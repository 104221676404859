<template>

  <div>
    <el-page-header content="添加物料" @back="$router.go(-1);" />
    <br><br>
    <template v-for="(list, row) in typeList">
      <el-row :key="row">
        <el-col :span="5">
          <el-select v-model="list.inputValue[0]" filterable placeholder="一级">
            <template v-for="item in list.type0">
              <el-option
                :key="item.describe"
                :label="item.describe"
                :value="item.sn"
              />
            </template>
          </el-select>
        </el-col>

        <el-col :span="5">
          <el-select v-model="list.inputValue[1]" filterable placeholder="二级">
            <template v-for="item in list.type1">
              <el-option
                :key="item.describe"
                :label="item.describe"
                :value="item.sn"
              />
            </template>
          </el-select>
        </el-col>

        <el-col :span="8">
          <el-input v-model="list.inputValue[2]" placeholder="型号" />
        </el-col>
        <el-col :span="2">
          <el-input v-model="list.inputValue[3]" placeholder="单位" />
        </el-col>

        <el-col :span="2">
          <el-button style="width: 100%;" @click="fun_add(list.inputValue[0], list.inputValue[1], list.inputValue[2], list.inputValue[3])">添加</el-button>
        </el-col>

        <el-col :span="2">
          <el-button style="width: 100%;" @click="fun_type_list_del(row)">删除</el-button>
        </el-col>
      </el-row>
    </template>

    <template>
      <el-row>
        <el-col :span="5">
          <el-input v-model="UserSnAdd.sn" placeholder="料号" />
        </el-col>
        <el-col :span="5">
          <el-input v-model="UserSnAdd.type" placeholder="类型" />
        </el-col>
        <el-col :span="8">
          <el-input v-model="UserSnAdd.model" placeholder="型号" />
        </el-col>
        <el-col :span="2">
          <el-input v-model="UserSnAdd.unit" placeholder="单位" />
        </el-col>
        <el-col :span="4">
          <el-button style="width: 100%;" @click="fun_UserSnAdd(UserSnAdd)">自编物料添加</el-button>
        </el-col>
      </el-row>
    </template>

    <br>
    <el-col :span="24">
      <el-button style="width:100%" @click="fun_type_list_add(typeList[0].type0.length - 1)">追加一行</el-button>
    </el-col>
    <br>
    <br><br>
    <table v-if="log.data.length > 0" class="el-table" style="width:100%">
      <tr>
        <th style="text-align:center; width:170px">时间</th>
        <th style="text-align:center; width:auto">用户</th>
        <th style="text-align:center; width:120px">编号</th>
        <th style="text-align:center;" />
      </tr>
      <template v-for="(data, row) in log.data" :v-bind:key="row">
        <tr :key="row">
          <td style="padding:2px 10px 2px 10px">{{ data.time }}</td>
          <td style="padding:2px 10px 2px 10px">{{ data.uid }} / {{ data.name }}</td>
          <td style="padding:2px 10px 2px 10px">{{ data.sn }}</td>
          <td style="padding:2px 10px 2px 10px">{{ data.log }}</td>
        </tr>
      </template>
    </table>

    <div :v-if="log.data">
      <el-pagination
        hide-on-single-page
        :page-size="log.per_page"
        :page-count="log.last_page"
        :current-page="log.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      typeList: [],
      UserSnAdd: { sn: '', type: '', model: '', unit: '个' }
    }
  },
  created: function () {
    this.get_type()
    this.fun_get_log()
  },
  methods: {
    get_type () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_type',
        data: {
          order: 'asc',
          current_page: 'all'
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.fun_get_log()
          this.type = res.data.data
          this.fun_type_list_add(-1)
        }
      })
    },
    fun_type_list_add (type0Row) {
      const type0 = []
      const type1 = []
      for (let i = 0; i < this.type.length; i++) {
        if (parseInt(this.type[i].type) === 0) { type0.push({ sn: this.type[i].sn, describe: this.type[i].describe }) }
        if (parseInt(this.type[i].type) === 1) { type1.push({ sn: this.type[i].sn, describe: this.type[i].describe }) }
      }
      if (type0.length > 0 && type1.length > 0) {
        if (type0Row < 0) {
          this.typeList = []
          for (let i = 0; i < type0.length; i++) {
            this.typeList.push({ type0: type0, type1: type1, inputValue: [type0[i].sn, '', '', '个'] })
          }
        } else {
          this.typeList.push({ type0: type0, type1: type1, inputValue: [type0[type0Row].sn, '', '', '个'] })
        }
      } else {
        this.$message.error('请先添加类型')
        this.$router.push({ path: '/material/type' })
      }
    },
    fun_type_list_del (row) {
      this.typeList.splice(row, 1)
    },
    fun_add (sn0, sn1, inputValue, inputUnit) {
      this.$axios({
        method: 'post',
        url: '/material/index/set_add',
        data: { sn0: sn0, sn1: sn1, describe: inputValue, unit: inputUnit }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.fun_get_log()
          this.$message.success(res.data.msg)
        }
      })
    },
    fun_UserSnAdd (dat) {
      this.$axios({
        method: 'post',
        url: '/material/index/user_sn_add',
        data: { sn: dat.sn, type: dat.type, model: dat.model, unit: dat.unit }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.fun_get_log()
          this.$message.success(res.data.msg)
        }
      })
    },
    fun_get_log () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_log',
        data: {
          sn: 'all',
          order: 'desc',
          screen: '%%',
          per_page: this.log.per_page,
          current_page: this.log.current_page,
          uid: window.sessionStorage.getItem('user_uid')
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
        } else {
          this.log = res.data.data
        }
      })
    },
    handleSizeChange (val) { this.log.per_page = val; this.fun_get_log() },
    handleCurrentChange (val) { this.log.current_page = val; this.fun_get_log() }
  }
}
</script>

<style lang="less" scoped>

</style>
